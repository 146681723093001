import React from 'react';
import LayoutES from '../components/layout.es';
import Seo from '../components/seo.es';
import SolutionsBlockES from '../components/solutionsblock.component.es';
import Benefit from '../components/benefit.component';
import BottomCTA from '../components/bottomCTA.component.es';

import '../styles/solution.scss';

import Zoom from 'react-medium-image-zoom';
import '../styles/imagezoom.css';

export default function SolutionWorkflow() {
	return (
		<LayoutES>
			<Seo title='Flujos de trabajo' />
			<section id='introSolution'>
				<div className='solutionBg solution-workflow'>
					<div id='introSolution-text'>
						<h1>Flujos de trabajo</h1>
						<p>
							Porque no es lo mismo tener procesos que{' '}
							<strong>trabajar por procesos</strong>.{' '}
						</p>
						<p>(Aunque el papelito con la ISO queda bonito, la verdad)</p>
					</div>
				</div>
			</section>
			<section id='benefitsSolution'>
				<Benefit posImage='R' imageUrl='/images/kuflow_orquesta.png'>
					<h2>KuFlow en cuatro pasos</h2>
					<p>
						KuFlow, básicamente, hace que todo el mundo tenga las manos
						ocupadas:
					</p>
					<ol>
						<li>
							<strong>Programas el flujo de trabajo</strong> con el proceso que
							ya exista en tu organización.
						</li>
						<li>
							Si algo se puede automatizar, lo <strong>automatizas</strong>.
						</li>
						<li>
							<strong>Publicas el proceso</strong> y ya el equipo puede
							ejecutarlo.
						</li>
						<li>
							El{' '}
							<strong>gestor puede ver el estado de todos los procesos</strong>{' '}
							e ir un paso por delante del equipo.
						</li>
					</ol>
					<Zoom>
						<img
							src='/images/kuflow_screenshot_processdetails_01_ES.png'
							width='280'
							style={{ margin: '0 auto' }}
							alt='Detalles del proceso'
						/>
					</Zoom>
				</Benefit>
				<Benefit posImage='L' imageUrl='/images/kuflow_workflow_advanced.png'>
					<h2>Flujos avanzados, herramientas avanzadas</h2>
					<p>
						Aquí las tareas, las variables, las llamadas a APIs y los webhooks
						se <strong>escriben, no se pintan</strong>.
					</p>
					<p>
						Si quieres hacer un diagrama conectando todo con flechas y cajas o
						hacer integraciones que funcionan casi siempre, vete buscando otra
						herramienta.
					</p>
					<p>
						¿Que cuál es <strong>la ventaja del código</strong>? Cuando tengas
						un flujo de 22 cajitas y “algo” no esté funcionando... Es igual de
						útil <strong>debuggear</strong> que rezar.
					</p>
					<Zoom>
						<img
							src='/images/kuflow_screenshot_code01.png'
							width='280'
							style={{ margin: '0 auto' }}
							alt='Código de flujo de trabajo'
						/>
					</Zoom>
				</Benefit>
				<Benefit posImage='R' imageUrl='/images/kuflow_workflow_sti.png'>
					<h2>STI (Sistema de Tozudez Integrado)</h2>
					<p>
						Imagínate que una tarea importante depende de conectarte al servidor
						de un cliente, que funciona <i>regulinchi</i>.
					</p>
					<p>
						<strong>
							Si esto fuera una aplicación de medio pelo, te llegaría un correo
							llorándote
						</strong>{' '}
						con que no se pudo completar la tarea.
					</p>
					<p>
						Pero KuFlow tiene un{' '}
						<strong>STI (Sistema de Tozudez Integrado)</strong> que va a estar
						de pesado las veces que le hayas dicho hasta que funcione.
					</p>
					<p>
						Si te interesa el apartado técnico, en{' '}
						<a href='https://docs.kuflow.com/developers/architecture'>
							la documentación
						</a>{' '}
						te contamos sobre nuestra arquitectura.
					</p>
				</Benefit>
				<Benefit posImage='L' imageUrl='/images/kuflow_workflow_devs.png'>
					<h2>De devs para devs</h2>
					<p>
						No es que nos guste ser imprescindibles, pero si se trata de
						integrar sistemas,{' '}
						<strong>¿quién lo va a hacer mejor que nosotros?</strong>
					</p>

					<p>Si quieren ponerle cien plugins al WordPress adelante.</p>

					<p>
						Pero si hablamos de{' '}
						<strong>
							un proceso de 25 pasos con 4 integraciones y 3 roles de equipo...
							Mejor que pase por manos expertas
						</strong>
						, sobre todo si se van a hacer modificaciones cada dos por tres.
					</p>
				</Benefit>
				<Benefit posImage='R' imageUrl='/images/kuflow_complexity.png'>
					<h2>Tu negocio no necesita más complicación</h2>
					<p>
						Cuando viene alguien nuevo a la empresa, después de un mes sigue
						aprendiendo esos “truquitos”. El clásico “esto es así, menos
						cuando...”.
					</p>
					<p>
						Reconócelo,
						<strong>tu negocio ya es de por sí complejo</strong> , es la propia
						naturaleza de las organizaciones. Simplificar problemas.
					</p>
					<p>
						<strong>
							KuFlow se encarga de que puedas centrarte en aquello por lo que te
							pagan
						</strong>{' '}
						que va a estar y que te olvides de todas esas particularidades.
					</p>
					<Zoom>
						<img
							src='/images/kuflow_screenshot_formdetails_01_ES.png'
							width='280'
							style={{ margin: '0 auto' }}
							alt='Detalles del formulario'
						/>
					</Zoom>
				</Benefit>
			</section>
			<SolutionsBlockES />
			<BottomCTA />
		</LayoutES>
	);
}
